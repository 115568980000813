//import Vue from 'vue'
//import { createApp } from 'vue'
//import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n'
// const Vue = createApp();
// Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  //console.log(process.env.VUE_APP_I18N_LOCALE)
  return messages
}

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })



const i18n = createI18n({
  legacy: true,
  allowComposition: true,
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages()
})




export default i18n