


export const LOADING = 'LOADING';
export const TOKEN = 'TOKEN';
export const LANGUAGE = 'LANGUAGE';
export const userID ='USERID';
export const userName = 'USERNAME';
export const userCompanyName = 'USERCOMPANYNAME';
export const userCompanyNumber = 'USERCOMPANYNUMBER';
export const userRegisterDate = 'USERREGISTERDATE';
export const userPoint = 'USERPOINT';
export const userLV = 'USERLV';
export const userContactPerson = 'USERCONTACTPERSON';
export const userContactPhoneNumber = 'USERCONTACTPHONENUMBER';
export const userPicture = 'USERPICTURE';
export const userUID = 'USERUID';
export const userRole = 'USERROLE';
export const userEmail = 'USERMAIL';



