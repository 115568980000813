import * as types from './mutations_types.js';
//import { sqllogin } from '../api/sql'
//import { sqllogin } from '../api/sql'
// 為了設定語系引入 Vue
//import Vue from 'vue';

export const state = {
  loading: false,
  token: '',
  lang: '',
  userID: '',
  userName: '',
  userCompanyName: '',
  userCompanyNumber: '',
  userRegisterDate: '',
  userPoint: '',
  userLV: '',
  userContactPerson: '',
  userContactPhoneNumber: '',
  userPicture: '',
  userUID: '',
}

export const actions = {
  toggleLoading({ commit }, isLoading) {
    commit(types.LOADING, isLoading);
  },
  // login
  actionLogin({ commit }, { email, password }) {
    // 目前沒找到比較好的範例 API，因此使用延遲 1.5s 模擬 ajax 以及簡單驗證。
    console.log('1. actionLogin');
    commit(types.LOADING, true); // 打開遮罩
    // 使用 Promise 包裝 API
    return new Promise((resolve, reject) => {
      // if (sqllogin(email, password)) {
      //   //if (email === 'administrator@admin' && password === '123') {
      //   console.log('2. Promise resolve')
      //   commit(types.TOKEN, email)
      //   commit(types.LOADING, false)
      //   resolve()
      // }
      // else {
      //   commit(types.LOADING, false)
      //   reject()
      // }
      setTimeout(() => {
        if (email === 'administrator@admin' && password === '123') {
          console.log('2. Promise resolve');
          //commit(types.TOKEN, '3345678'); // success 儲存 token
          commit(types.TOKEN, email)
          commit(types.LOADING, false); // 關閉遮罩
          resolve(); // resolve 結果會在 then 裡面收到
        }
        // error
        else {
          commit(types.LOADING, false); // 關閉遮罩
          reject(); // reject 結果會在 catch 裡面收到
        }
      }, 1500);
    });
  },
  setLanguage({ commit }, lang) {
    commit(types.LANGUAGE, lang);
  },
}

export const mutations = {
  [types.LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [types.TOKEN](state, token) {
    state.token = token;
  },
  [types.LANGUAGE](state, setlang) {
    state.lang = setlang;
    // 設定 Vue config 將會改變使用的語系
    //Vue.config.lang = state.lang;
  },
  [types.userID](state, userID) {
    state.userID = userID;
  },
  [types.userName](state, userName) {
    state.userName = userName;
  },
  [types.userCompanyName](state, userCompanyName) {
    state.userCompanyName = userCompanyName;
  },
  [types.userCompanyNumber](state, userCompanyNumber) {
    state.userCompanyNumber = userCompanyNumber
  },
  [types.userRegisterDate](state, userRegisterDate) {
    state.userRegisterDate = userRegisterDate
  },
  [types.userPoint](state, userPoint) {
    state.userPoint = userPoint
  },
  [types.userLV](state, userLV) {
    state.userLV = userLV
  },
  [types.userContactPerson](state, userContactPerson) {
    state.userContactPerson = userContactPerson
  },
  [types.userContactPhoneNumber](state, userContactPhoneNumber) {
    state.userContactPhoneNumber = userContactPhoneNumber
  },
  [types.userPicture](state, picture) {
    state.userPicture = picture;
  },
  [types.userUID](state, userUID) {
    state.userUID = userUID
  },
  [types.userRole](state, userRole) {
    state.userRole = userRole
  },
  [types.userEmail](state, userEmail) {
    state.userEmail = userEmail
  },
}