// get count & todo
// export const getCountandTodo = state => {
//     return {
//       count: state.count.count,
//       todo: state.todo.todos,
//     }
//   }
  
  // just return all state
  export const getAllstate = state => state
  
  // loading state
  export const getLoading = state => state.loading;
  
  // Language
  export const getLanguage = state => state.lang;
  
  export const getuserID = state=>state.userID;
  export const getuserName= state=>state.userName;
  export const getToken = state=>state.token;