"use strict";

//import Vue from 'vue';
import axios from "axios";
import Dev from '@/api/isDev'
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  // 用來判斷是否解析 Promise 的 狀態碼範圍
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  },
  Headers: {
    //'Access-Control-Allow-Origin': 'https://access.line.me'
    'Access-Control-Allow-Origin': '*'

  }
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (Dev.isDev) {
      console.log(config)
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    //console.log(response)
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

//Plugin.install = function(Vue, options) {
_axios.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

//Vue.use(Plugin)
//Vue.use(_axios)

//export default Plugin;
export default _axios
