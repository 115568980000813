
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/MainWindow.vue'),
        meta: {
            requiresAuth: true,
            title: 'Laser Marking Calibration System',
        },

    },
    {
        path: '/',
        name: 'Login',
        component: () => import('@/views/Login-Window.vue'),
        meta: {
            title: 'Laser Marking Calibration System',
        },
    },
    {
        path: '/receive',
        name: 'receive',
        component: () => import('@/views/Receive-Page.vue')
    },
    {
        path: '/Calibration',
        name: 'LaserCalibration',
        component: () => import('@/views/LaserCalibration.vue'),
        meta: {
            requiresAuth: true,
            title: 'Laser Marking Calibration System',
        },
    }


]

export default routes