//import { createStore } from 'vuex'
//import Vue from 'vue'
//import Vuex from 'vuex'
import { createStore } from 'vuex'
import * as getters from './getters.js'
import { state, actions, mutations } from './root.js'
//Vue.use(Vuex)

// export default new Vuex.Store({
//   state,
//   actions,
//   mutations,
//   getters,
//   modules: {

//   }
// })

const store = createStore({
  state,
  actions,
  mutations,
  getters,
  modules: {

  }
})

export default store

// export default createStore({
//   state: {
//     userName: '',
//     userID: '',
//     userToken: '',
//     userPIC: '',
//   },
//   actions: {
//     GetuserName(context) {
//       const GetuserName = !context.state.userName;
//       console.log('gn:' + context)
//       context.commit('RecuserName', GetuserName);
//     },
//     GetuserID(context) {
//       const GetuserID = !context.state.userID;
//       context.commit('RecuserID', GetuserID);
//     },
//     GetuserToken(context) {
//       const GetuserToken = !context.state.userToken;
//       context.commit('RecuserToken', GetuserToken);
//     },
//     GetuserPIC(context) {
//       const GetuserPIC = !context.state.userPIC;
//       context.commit('RecuserPIC', GetuserPIC);
//     },

//   },


//   getters: {
//   },
//   mutations: {
//     RecuserName(state, GetuserName) {
//       console.log('rn:' + GetuserName)
//       state.userName = GetuserName;
//     },
//     RecuserID(state, GetuserID) {
//       state.userID = GetuserID;
//     },
//     RecuserToken(state, GetuserToken) {
//       state.userToken = GetuserToken;
//     },
//     RecuserPIC(state, GetuserPIC) {
//       state.userPIC = GetuserPIC;
//     }

//   },

//   modules: {
//   }
// })
