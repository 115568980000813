<template>
  <router-view />
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  //name: 'app',
  components: {
    //HelloWorld
  },
  metaInfo: {
    title: 'Laser Marking Calibration System'
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

body::-webkit-scrollbar {
  display: none;

}

body {
  -ms-overflow-style: none;

}

html {

  overflow: -moz-hidden-unscrollable;
}

#nprogress .bar {
  background: lime !important;
  height: 4px !important;
  ;
}
</style>
