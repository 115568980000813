//import VueRouter from "vue-router";
import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '@/store'
//import Vue from "vue";
//import { createApp } from 'vue'
import Dev from '@/api/isDev'
import NProgress from "nprogress";
import 'nprogress/nprogress.css'


//NProgress.inc(0.2);
NProgress.configure({ easing: 'ease', speed: 2000, showSpinner: false, trickle: false })



// const router = new VueRouter({
//   mode: 'history',
//   routes,
// });


const router = createRouter({
  history: createWebHistory(),
  routes,
})


export default router
router.beforeEach((to, from, next) => {

  NProgress.start();
  if (Dev.isDev()) {
    console.log('to=', to.fullPath, '| from=', from.fullPath);
  }
  if (to.matched.some(record => {
    if (Dev.isDev()) {
      console.log(record.name, record.meta.requiresAuth);
    }
    return record.meta.requiresAuth;
  })) {
    if (sessionStorage.getItem("store")) {
      store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage.getItem("store"))))
    }
    // 如果沒有 token 
    if (Dev.isDev()) {
      console.log(store.state.token)
      console.log('token?', store.state.token);
    }
    if (store.state.token == '') {
      // 轉跳到 login page

      next({ name: 'Login', });
    } else {
      next(); // 往下繼續執行
    }
  } else {
    next(); // 往下繼續執行
  }

});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

