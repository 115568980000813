//import Vue from 'vue' //vue2.x
import { createApp } from 'vue' //vue3.x
//import './plugins/axios'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
//import VueI18n from 'vue-i18n'
//import { createI18n } from 'vue-i18n'
import './plugins/axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

//import _axios from './plugins/axios'
import * as types from '@/store/mutations_types.js';
import isLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import NProgress from 'nprogress'
//Vue.config.productionTip = false
//_axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
//Vue.prototype.$http = _axios
//import createAlloyWorker from 'alloy-worker/src/worker/index.ts'


store.commit(types.LANGUAGE, (navigator.language || navigator.browserLanguage).toLowerCase())

i18n.global.locale = store.getters.getLanguage
console.log(process.env.NODE_ENV)
NProgress.configure({
  minimum: 0.01,
  easing: 'ease',
  speed: 500,
  trickle: false,
  showSpinner: false,

})
//createApp.prototype.$http = _axios;

//Vue.component('isLoading', isLoading)

//vue 2.x
// new Vue({
//   router,
//   store,
//   i18n,

//   render: h => h(App)
// }).$mount('#app')

//vue3.x
createApp(App)
  .use(router)
  //.use(createAlloyWorker({ workerName: 'lmcs-test', }))
  .use(store)
  .use(i18n)
  .use(VueAxios, axios)
  .use(NProgress)
  .use(ElementPlus, {})
  .use(isLoading)
  .mount('#app')

//i18n.locale = (navigator.language || navigator.browserLanguage).toLowerCase()

//console.log('main:', store.getters.getLanguage)
//console.log('main i18n:', i18n.global.locale)